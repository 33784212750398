import React from "react";
import "../../App.css";

function ReturnComponent() {
  
  const returnPage = () => {
    window.history.go(-1);
  };

  return (
    <div>
      <span onClick={returnPage}  
      className="backButtonCss">
        <span className="arrowCss">←</span> Regresar
      </span>
    </div>
  );
}

export default ReturnComponent;
