import React, { useState } from "react";
import "../App.css";
import videoPlaceHolder from "../images/video.png";
import imagePlaceHolder from "../images/image.png";

import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Button } from "react-bootstrap";
import Card from "react-bootstrap/Card";

import { useLocation, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import ReturnComponent from "./controls/returnComponent";
import RegisterComponent from "./registerComponent";


const MultimediaComponent = () => {
  const { state } = useLocation();
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);
  const [file3, setFile3] = useState(null);
  const [file4, setFile4] = useState(null);

  const [file1Url, setFile1Url] = useState([]);
  const [file2Url, setFile2Url] = useState([]);
  const [file3Url, setFile3Url] = useState([]);
  const [file4Url, setFile4Url] = useState([]);

  const navigate = useNavigate();
  let arrFiles = [];
  let arrFilesImages = [];

  const handleChange1 = (e) => {
    setFile1Url(URL.createObjectURL(e.target.files[0]));
    setFile1(e.target.files[0]);
  };

  const handleChange2 = (e) => {
    setFile2Url(URL.createObjectURL(e.target.files[0]));
    setFile2(e.target.files[0]);
  };

  const handleChange3 = (e) => {
    setFile3Url(URL.createObjectURL(e.target.files[0]));
    setFile3(e.target.files[0]);
  };

  const handleChange4 = (e) => {
    setFile4Url(URL.createObjectURL(e.target.files[0]));
    setFile4(e.target.files[0]);
  };

  const deleteImage = (numberImage) => {
    if (numberImage === 1) {
      setFile1Url([]);
      setFile1(null);
    }
    if (numberImage === 2) {
      setFile2Url([]);
      setFile2(null);
    }
    if (numberImage === 3) {
      setFile3Url([]);
      setFile3(null);
    }
    if (numberImage === 4) {
      setFile4Url([]);
      setFile4(null);
    }
  };

  if (file1) {
    arrFiles.push(file1);
    arrFilesImages.push(file1Url);
  }
  if (file2) {
    arrFiles.push(file2);
    arrFilesImages.push(file2Url);
  }
  if (file3) {
    arrFiles.push(file3);
    arrFilesImages.push(file3Url);
  }
  if (file4) {
    arrFiles.push(file4);
    arrFilesImages.push(file4Url);
  }

  const sendData = () => {
    const myMultimediaObject = {
      customerName: state.objData.name,
      order: state.objData.folioNumber,
      message: state.objData.message,
      files: arrFiles,
      filesImages: arrFilesImages,
    };
    navigate(`/complete`, { state: { objData: myMultimediaObject } });
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {state ? (
        <div>
          <ReturnComponent />
          <h1 className="subtitleCss">¡Comparte momentos inolvidables!</h1>
          <br />
          <div style={{textAlign:"center"}}>
          <span className="mesageCss">
            Para hacerlo aún más perfecto sube tres imágenes y un video de 35 segundos aprox. 
          </span>
          </div>
          
          <br /> <br /> <br />
          <Form.Group>
            <Row className="justify-content-md-center mb-4">
              <Col style={{ display: "flex", justifyContent: "right" }}>
                <Card style={{ width: "10rem" }}>
                  <div>
                    {!file1Url.includes("blob") && (
                      <label htmlFor="boton-archivo1">
                        <img
                          alt=""
                          className="imagesPlaceHolderCss"
                          src={imagePlaceHolder}
                        />
                      </label>
                    )}
                    <input
                      id="boton-archivo1"
                      type="file"
                      style={{ display: "none" }}
                      onChange={handleChange1}
                      accept="image/*"
                    />
                    {file1Url.includes("blob") && (
                      <div style={{ position: "relative" }}>
                        <img
                          alt=""
                          className="imagesPlaceHolderCss"
                          src={file1Url}
                        />
                        <span
                          className="btnDeleteCss"
                          onClick={() => deleteImage(1)}
                        >
                          Eliminar
                        </span>
                      </div>
                    )}
                  </div>
                </Card>
              </Col>
              <Col>
                <Card style={{ width: "10rem" }}>
                  <div>
                    {!file2Url.includes("blob") && (
                      <label htmlFor="boton-archivo2">
                        <img
                          alt=""
                          className="imagesPlaceHolderCss"
                          src={imagePlaceHolder}
                        />
                      </label>
                    )}
                    <input
                      id="boton-archivo2"
                      type="file"
                      style={{ display: "none" }}
                      onChange={handleChange2}
                      accept="image/*"
                    />
                    {file2Url.includes("blob") && (
                      <div style={{ position: "relative" }}>
                        <img
                          alt=""
                          className="imagesPlaceHolderCss"
                          src={file2Url}
                        />
                        <span
                          className="btnDeleteCss"
                          onClick={() => deleteImage(2)}
                        >
                          Eliminar
                        </span>
                      </div>
                    )}
                  </div>
                </Card>
              </Col>
            </Row>

            <Row className="justify-content-md-center">
              <Col style={{ display: "flex", justifyContent: "right" }}>
                <Card style={{ width: "10rem" }}>
                  <div>
                    {!file3Url.includes("blob") && (
                      <label htmlFor="boton-archivo3">
                        <img
                          alt=""
                          className="imagesPlaceHolderCss"
                          src={imagePlaceHolder}
                        />
                      </label>
                    )}
                    <input
                      id="boton-archivo3"
                      type="file"
                      style={{ display: "none" }}
                      onChange={handleChange3}
                      accept="image/*"
                    />
                    {file3Url.includes("blob") && (
                      <div style={{ position: "relative" }}>
                        <img
                          alt=""
                          className="imagesPlaceHolderCss"
                          src={file3Url}
                        />
                        <span
                          className="btnDeleteCss"
                          onClick={() => deleteImage(3)}
                        >
                          Eliminar
                        </span>
                      </div>
                    )}
                  </div>
                </Card>
              </Col>
              <Col>
                <Card style={{ width: "10rem" }}>
                  <div>
                    {!file4Url.includes("blob") && (
                      <label htmlFor="boton-archivo4">
                        <img
                          alt=""
                          className="imagesPlaceHolderCss"
                          src={videoPlaceHolder}
                        />
                      </label>
                    )}
                    <input
                      id="boton-archivo4"
                      type="file"
                      style={{ display: "none" }}
                      onChange={handleChange4}
                      accept="video/*"
                    />
                    {file4Url.includes("blob") && (
                      <div style={{ position: "relative" }}>
                        <video className="imagesPlaceHolderCss" controls>
                          <source src={file4Url} type="video/mp4" />
                        </video>
                        <span
                          className="btnDeleteCss"
                          onClick={() => deleteImage(4)}
                        >
                          Eliminar
                        </span>
                      </div>
                    )}
                  </div>
                </Card>
              </Col>
            </Row>
          </Form.Group>
          <br />
          <br />
          <Form.Group className="mb-2">
            <div className="d-grid gap-1">
              <Button
                style={{
                  backgroundColor: "#555555",
                  borderColor: "#555555",
                  fontWeight: "bold",
                }}
                onClick={sendData}
              >
                Siguiente
              </Button>
            </div>
          </Form.Group>
        </div>
      ) : (
        <RegisterComponent />
      )}

      <br />
      <br />
    </motion.div>
  );
};
export default MultimediaComponent;
