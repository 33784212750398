

import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "../App.css";
import ReturnComponent from "./controls/returnComponent";
import ImagePreview from "../components/controls/imagePreview";
import RegisterComponent from "./registerComponent";

function PreviewComponent() {

 
  const {state } = useLocation();
  const navigate = useNavigate();
  const [width, setWidth] = useState(0);
  const carousel = useRef();

  useEffect(() => {
    
    if (state === null) {
      return navigate("/");
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      setWidth(carousel.current.scrollWidth - carousel.current.offsetWidth);
    }
  },[width]);
  
  if (state === null) {
    return navigate("/");
  }

  let arrImages = [];
  let video = "";

  for (let index = 0; index < state.objData.files.length; index++) {
    const element = state.objData.files[index].name.split(".").pop();

    if (element === "mp4" || element === "mov") {
      video = state.objData.filesImages[index];
    } else {
      arrImages.push(state.objData.filesImages[index]);
    }
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {state ? (
        <div>
          <ReturnComponent />
          <h1 className="registerTitleCss" style={{ textAlign: "center" }}>
            ¡Hola!
          </h1>
          <br />
          <p style={{ color: "gray", textAlign: "center" }}>
            <span style={{ fontWeight: "bold" }}>
              {state.objData.customerName}
            </span>{" "}
            te ha dedicado este mensaje con mucho afecto.
          </p>{" "}
          <hr />
          <br />
          <p style={{ color: "gray", textAlign: "center" }}>
            {" "}
            {state.objData.message}
          </p>
          <br />
          <Row className="justify-content-md-center">
            <Col style={{ display: "flex", justifyContent: "center" }}>
              <motion.div className="slider-container" ref={carousel}>
                <motion.div
                  className="slider"
                  drag="x"
                  dragConstraints={{ right: 0, left: -width }}
                >
                  {arrImages?.map((a, b) => (
                    <ImagePreview
                      key="b"
                      image={arrImages[b]}
                      type="image"
                    ></ImagePreview>
                  ))}
                  {video && (
                    <ImagePreview video={video} type="video"></ImagePreview>
                  )}
                </motion.div>
              </motion.div>
            </Col>
          </Row>
        </div>
      ) : (
        <RegisterComponent />
      )}
    </motion.div>
  );
}

export default PreviewComponent;
