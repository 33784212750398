import React from 'react'

function footerComponent() {
  return (
    <div>
      <br /><br />
      <div className='text-center p-4' style={{ fontSize: 12, color: 'gray' }}>
        © 2023 Copyright:&nbsp;
        <a className='text-reset fw-bold' href='https://obseky.com/page'>
          Obseky.com
        </a>
      </div>
    </div>
  )
}

export default footerComponent
