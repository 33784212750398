import "./App.css";

import Container from "react-bootstrap/Container";
import HeaderComponent from "./components/headerComponent";
import FooterComponent from "./components/footerComponent";
import RegisterComponent from "./components/registerComponent";
import MessageComponent from "./components/messageComponent";
import MultimediaComponent from "./components/multimediaComponent";
import CompleteComponent from "./components/completeComponent";
import SplashComponent from "./components/splashComponent";
import PreviewComponent from "./components/previewComponent";
import UnlockComponent from "./components/unlockComponent";
import MessageUnlockedComponent from "./components/messageUnlockedComponent";
import { Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { useState, useEffect } from "react";

function App() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const location = useLocation();

  return (
    <>
      {loading ? (
        <AnimatePresence mode="wait">
          <SplashComponent />
        </AnimatePresence>
      ) : (
        <Container className="ContainerCss">
          <HeaderComponent></HeaderComponent>
          <AnimatePresence mode="wait">
            <Routes location={location} key={location.pathname}>
                <Route index element={<RegisterComponent />} />
                <Route path="/register" element={<RegisterComponent />} />
                <Route path="/message" element={<MessageComponent />} />
                <Route path="/multimedia" element={<MultimediaComponent />} />
                <Route path="/complete" element={<CompleteComponent />} />
                <Route path="/preview" element={<PreviewComponent />} />
                <Route path="/unlock/:order" element={<UnlockComponent />} />
                <Route
                  path="/messageUnlock"
                  element={<MessageUnlockedComponent />}
                />
            </Routes>
          </AnimatePresence>
          <FooterComponent></FooterComponent>
        </Container>
      )}
    </>
  );
}
export default App;
