import React from "react";
import Alert from "react-bootstrap/Alert";

function alertWarningComponent(props) {
  return (
    <div><br/>
      <Alert variant="warning" style={{ textAlign:'center'}}>
        <Alert.Heading>¡Aviso!</Alert.Heading>  <hr />
        <p>{props.message}</p>
      
      </Alert>
    </div>
  );
}

export default alertWarningComponent;
