import React, { useState, useEffect } from "react";
import "../App.css";
import unlockImage from "../images/unlock.png";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import AlertWarningComponent from "./controls/alertWarningComponent";
import LoaderComponent from "./loaderComponent";
import { useNavigate, useParams } from "react-router-dom";
import { motion } from "framer-motion";

function UnlockComponent() {
  const params = useParams(0);
  const navigate = useNavigate();
  const [codeNumber, setCodeNumber] = useState("");
  const [messageCode, setMessageCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [statusServer, SetStatusServer] = useState(true);

  useEffect(() => {
    fetch(`https://mistarjetasbackend.com/api/finalcustomer/${params.order}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          SetStatusServer(false);
        }
        if (data.status === 401) {
          setMessageCode("Aún no se ha cargado información en esta tarjeta");
        }
        if (data.status === 402) {
          setMessageCode("Tarjeta inactiva y sin asignar");
        }
        if (data.status === 403) {
          setMessageCode("Tajeta inactiva y asignada");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  });

  const handleChange = (e) => {
    setCodeNumber(e.target.value);
    setMessageCode();
  };

  const handleSearchingCode = (e) => {
   
    if (codeNumber) {
      setLoading(true);
      fetch(
        `https://mistarjetasbackend.com/api/finalcustomer/${params.order}/${codeNumber}/2`
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.status === 200) {
            navigate(`/messageUnlock`, { state: { objData: data.result[0] } });
          }
          if (data.status === 404) {
            setMessageCode("No existe la información solicitada");
          }
          if (data.status === 405) {
            setMessageCode(
              "Tus archivos ya no estan disponibles. Ya han pasado 7 días o más"
            );
          }
          if (data.status === 406) {
            setMessageCode(
              "El código secreto no coincide con la tarjeta escaneada"
            );
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }else{
      setMessageCode("El campo 'Código' es obligatorio");
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div style={{ textAlign: "center" }}>
        <img style={{ width: "7rem" }} src={unlockImage} alt="" />
      </div>
      <br />
      <br />
      <h1 className="unclockCss">
        ¡Descubre quién te ha enviado esta tarjeta!
      </h1>
      <br />
      <br />
      <Form.Group className="mb-2" controlId="login">
        <Row className="justify-content-md-center">
          <Col md="10">
            <Form.Control
              size="md"
              className="inputCss"
              type="text"
              placeholder="Ingresa el código"
              name="name"
              onChange={handleChange}
              value={codeNumber}
              maxLength={7}
              required
              disabled= {!!statusServer} 
            />
          </Col>
        </Row>
        {messageCode && <AlertWarningComponent message={messageCode} />}
        <br />
        <Row className="justify-content-md-center">
          <Col md="10">
            <br />
            <br />
            <Form.Group className="mb-2">
              <div className="d-grid gap-1">
                <div className="d-grid gap-1">
                  {loading ? (
                    <LoaderComponent />
                  ) : (
                    <Button
                      disabled={!!statusServer}
                      onClick={handleSearchingCode}
                      className="stylesButton"
                    >
                      Descubrir
                    </Button>
                  )}
                </div>
              </div>
            </Form.Group>
          </Col>
        </Row>
      </Form.Group>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </motion.div>
  );
}

export default UnlockComponent;
