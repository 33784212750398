import React from "react";
import { motion } from "framer-motion";
import "../../App.css";


function imagePreview(props) {
  return (
    <motion.div className="item">
      
      <div>
        {props.type === "image" ? (
          <img src={props.image} className="imgCss" alt=""></img>
        ) : (
          <video className="imgCss" controls>
            <source src={props.video} type="video/mp4" />
          </video>
        )}
      </div>
    </motion.div>
  );
}

export default imagePreview;
