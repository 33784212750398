import React, { useState } from "react";
import "../App.css";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import LoaderComponent from "./loaderComponent";
import AlertSuccessComonent from "./controls/alertSuccessComponent";
import ReturnComponent from "./controls/returnComponent";
import RegisterComponent from "./registerComponent";

function CompleteComponent() {
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  const { state } = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [responseServer, setResponseServer] = useState(false);

  const handlePreview = () => {
    const myCompleteObject = {
      customerName: state.objData.customerName,
      order: state.objData.order,
      message: state.objData.message,
      files: state.objData.files,
      filesImages: state.objData.filesImages,
    };
    navigate(`/preview`, { state: { objData: myCompleteObject } });
  };

  const actionSuccess = () => {
    navigate(`/`);
  };

  const handleSave = () => {
    try {
      let formData = new FormData();
      setLoading(true);
      formData.append("businessName", "Mensaje Sorpresa");
      formData.append("customerName", state.objData.customerName);
      formData.append("order", state.objData.order);
      formData.append("cardId", 1);
      formData.append("message", state.objData.message);
      formData.append("termsAndConditions", 1);
      formData.append("privacyPolicy", 1);

      for (let index = 0; index < state.objData.files.length; index++) {
        formData.append("files", state.objData.files[index]);
      }

      fetch("https://mistarjetasbackend.com/api/customers", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          setLoading(false);
          setResponseServer(true);
          setTimeout(actionSuccess, 3000);
          localStorage.clear();
        });
    } catch (error) {
      setResponseServer(false);
      console.log(error);
    }
  };
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {state ? (
        <div>
          {loading || responseServer ? ""  :<ReturnComponent />}

          <h1 className="userCompleteCss">¡Listo { state.objData.customerName  }!</h1>
          <br />
          <div style={{ textAlign: "center" }}>
            <span className="userCompleteSubTitleCss">
              ¿Quieres ver cómo quedo?
            </span>
          </div>
          <br />
          <br />
          <br />
          <br />
          <Form.Group className="mb-2">
            <div className="d-grid gap-1">
              <Button
                disabled={!!loading || responseServer}
                size="md"
                style={{
                  backgroundColor: "#555555",
                  borderColor: "#555555",
                  fontWeight: "bold",
                }}
                onClick={handlePreview}
              >
                Ver
              </Button>
            </div>
          </Form.Group>
          <br />
          <Form.Group className="mb-2">
            <div className="d-grid gap-1">
              {loading ? (
                <LoaderComponent />
              ) : (
                <Button
                  disabled={!!responseServer}
                  size="md"
                  style={{
                    backgroundColor: "#555555",
                    borderColor: "#555555",
                    fontWeight: "bold",
                  }}
                  onClick={handleSave}
                >
                  Guardar
                </Button>
              )}
            </div>
            {responseServer && <AlertSuccessComonent />}
          </Form.Group>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      ) : (
        <RegisterComponent />
      )}
    </motion.div>
  );
}

export default CompleteComponent;
