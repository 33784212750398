// import React, { useState, useEffect } from "react";
import React from "react";
import logo from "../images/logo.png";
import monio from "../images/monio.png";
// import monio2 from "../images/monio2.png";
// import monio3 from "../images/monio3.png";
import "../App.css";

function HeaderComponent() {

  // const [headerImage, setHeaderImage] = useState("");
  // useEffect(() => {
  //   if (obj !== "") {
  //     fetch(`http://localhost:3001/api/finalcustomer/getHeaderImages/${obj}`)
  //       .then((response) => response.json())
  //       .then((data) => {
  //         if (data.status === 200) {
  //           if (data.result === "monio")  { setHeaderImage(monio);}
  //           if (data.result === "monio2") { setHeaderImage(monio2);}
  //           if (data.result === "monio3") { setHeaderImage(monio3);}
  //         } else {
  //           setHeaderImage(monio);
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   } else {
  //     setHeaderImage(monio);
  //   }
  // });

  return (
    <div>
      <img
        className="logo1"
        src={monio}
        style={{ marginLeft: "-47px", marginTop: "-39px", width: "195px" }}
        alt=""
      ></img>
      <img className="logo" src={logo} alt=""></img>
    </div>
  );
}

export default HeaderComponent;
