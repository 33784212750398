import React from "react";
import logo from "../images/logo.png";
import "../App.css";
import { motion } from "framer-motion";

const splashComponent = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <img src={logo} alt="" className="logoSplashCss" />
    </motion.div>
  );
};

export default splashComponent;
