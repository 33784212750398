import React, { useState } from "react";
import "../App.css";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { motion } from "framer-motion";
import AlertWarningComponent from "./controls/alertWarningComponent";
import { useNavigate } from "react-router-dom";
import termsFile from "../images/TyC_AvisoPrivacidad.pdf";
import LoaderComponent from "./loaderComponent";

const RegisterComponent = () => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [folio, SetFolio] = useState("");
  const [terms, SetTerms] = useState();

  const [nameError, setNameError] = useState("");
  const [folioError, setFolioError] = useState("");
  const [termsError, setTermsError] = useState("");
  const [responseMessage, setResponseMessage] = useState(null);
  const navigate = useNavigate();

  const handleChangeName = (e) => {
    setName(e.target.value);
    setNameError("");
  };
  const handleChangeFolio = (e) => {
    SetFolio(e.target.value);
    setFolioError("");
  };
  const handleChangeTerms = (e) => {
    SetTerms(e.target.checked);
    setTermsError("");
  };
  const validationsForm = () => {
    setLoading(true);
    let regexName = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;

    if (!name.trim()) {
      setNameError("El campo 'Nombre' es requerido");
      setLoading(false);
    } else if (!regexName.test(name.trim())) {
      setNameError("El campo 'Nombre' es invalido");
      setLoading(false);
    }
    if (!folio.trim()) {
      setFolioError("El campo 'Folio' es requerido");
      setLoading(true);
    }
    if (terms === false || terms === undefined) {
      setTermsError("Debes aceptar los TyC / Aviso de privacidad");
      setLoading(false);
    }

    if (folio !== "" && name !== "" && terms !== false) {
      const myRegisterObject = {
        name: name,
        folioNumber: folio,
      };
 
      fetch(
        `https://mistarjetasbackend.com/api/customers/${folio}/64937ac00bc1663b9ebfd04f`
      )
        .then((response) => response.json())
        .then((data) => {
          let resp = data.message === "OK" ? "" : data.message;
          setResponseMessage(resp);

          if (data.status === 200) {
            setLoading(false);
            navigate(`/message`, { state: { objData: myRegisterObject } });
          }else{
            setLoading(false);
          }
        })
        .catch((error) => {
          alert(error);
          setLoading(false);
        });
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div>
        <h1 className="registerTitleCss">¡Hola!</h1>
        <br />
        <h1 className="subtitleCss">Haz que esa persona se emocione.</h1>

        <br />
        <br />
        <br />
        {}
        <form>
          <Form.Group className="mb-2" controlId="login">
            <Row className="justify-content-md-center">
              <Col md="10">
                <Form.Control
                  size="md"
                  className="inputCss"
                  type="text"
                  placeholder="Ingresa tu nombre"
                  name="name"
                  onChange={handleChangeName}
                  value={name}
                  maxLength={35}
                  required
                />
                {nameError && <p className="styles">{nameError}</p>}
                <br />

                <Form.Control
                  size="md"
                  className="inputCss"
                  type="text"
                  placeholder="Ingresa el número de folio"
                  name="folio"
                  onChange={handleChangeFolio}
                  value={folio}
                  maxLength={8}
                  required
                />
                {folioError && <p className="styles">{folioError}</p>}
              </Col>
            </Row>
          </Form.Group>
          <br />
          <Form.Group className="chkCss">
            <Row className="justify-content-md-center">
              <Col md="2">
                <Form.Check
                  type="checkbox"
                  name="terms"
                  checked={terms}
                  value={terms}
                  onChange={handleChangeTerms}
                  required
                />
              </Col>
              <Col md="10">
                <a
                  className="linksRegisterCss"
                  target="_blank"
                  rel="noreferrer"
                  href={termsFile}
                >
                  Términos y condiciones / Aviso de privacidad
                </a>
                {termsError && <p className="styles">{termsError}</p>}
              </Col>
            </Row>
          </Form.Group>
          <br />
          <br />
          <Form.Group className="mb-2">
            <div>
              <div className="d-grid gap-1" >
                {loading ? (
                  <LoaderComponent />
                ) : (
                  <Button onClick={validationsForm} className="stylesButton">
                    Entrar
                  </Button>
                )}
              </div>
              {responseMessage && (
                <AlertWarningComponent message={responseMessage} />
              )}
            </div>
          </Form.Group>
          <br />
          <br /> <br /> <br />
          <br />
        </form>
      </div>
    </motion.div>
  );
};

export default RegisterComponent;
