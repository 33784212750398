import React from 'react'
import loading from '../images/loader.gif';

function loaderComponent() {
  return (
    <div>
        <img src={loading} style={{width:"31px", marginLeft: "11.5rem"}} ></img>
    </div>
  )
}

export default loaderComponent
