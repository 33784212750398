import React, { useState, useEffect } from "react";
import "../App.css";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Button } from "react-bootstrap";
import { motion } from "framer-motion";
import { useNavigate, useLocation } from "react-router-dom";
import { useLocalStorage } from "../hooks/useLocalStorage";
import ReturnComponent from "./controls/returnComponent";

import RegisterComponent from "./registerComponent";

const MessageComponent = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [totalCharacters, setTotalCharacters] = useState(500);
  const [error, setError] = useState("");

  const [dataCategories, setDataCategories] = useState(null);
  const [idCategorySelected, setIdCategorySelected] = useLocalStorage(
    "idCategorySelected",
    0
  );

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    fetch(`https://mistarjetasbackend.com/api/categories`)
      .then((response) => response.json())
      .then((data) => {
        setDataCategories(data.result);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const [dataSubCategories, setSubDataCategories] = useState(null);
  const [messageSelected, setMessageSelected] = useLocalStorage(
    "messageSelected",
    ""
  );
  const [idSubCategory, setIdSubCategory] = useLocalStorage("idSubCategory", 0);

  useEffect(() => {
    if (idCategorySelected)
      fetch(
        `https://mistarjetasbackend.com/api/subCategories/${idCategorySelected}`
      )
        .then((response) => response.json())
        .then((data) => {
          setSubDataCategories(data.result);
        })
        .catch((error) => {
          console.error(error);
        });
  }, [idCategorySelected]);

  const handleChangeCategory = (e) => {
    setIdCategorySelected(e.target.value);
    setMessageSelected("");
    setTotalCharacters(500);
  };

  const handleChangeSubCategory = (e) => {
    let index = e.target.selectedIndex;
    setMessageSelected(e.target.options[index].value); //.text
    setIdSubCategory(e.target.value);
  };

  const handleChangeMessage = (e) => {
    setMessageSelected(e.target.value);
  };

  const ValidationForm = () => {
    const myMessageObject = {
      name: state.objData.name,
      folioNumber: state.objData.folioNumber,
      message: messageSelected,
    };

    if (messageSelected.trim() !== "") {
      navigate(`/multimedia`, { state: { objData: myMessageObject } });
    } else {
      setError("El campo 'Mensaje' es requerido");
    }
  };

  const handleBlur = (e) => {
    if (e.target.value !== "") {
      setError("");
    } else {
      setError("El campo 'Mensaje' es requerido");
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {state ? (
        <div>
          <ReturnComponent />
          <h1 className="subtitleCss">¡Tu mensaje será el toque perfecto!</h1>
          <Form.Group>
            <Row className="justify-content-md-center heightSelectCss">
              <Col md="10">
                <Form.Select
                  className="selectCss mb-4"
                  value={idCategorySelected}
                  onChange={handleChangeCategory}
                >
                  <option>Selecciona una categoría</option>
                  {dataCategories?.map((elem) => (
                    <option key={elem._id} value={elem._id}>
                      {elem.category}
                    </option>
                  ))}
                </Form.Select>

                <Form.Select
                  className="selectCss"
                  value={idSubCategory}
                  onChange={handleChangeSubCategory}
                >
                  <option>Selecciona un mensaje</option>
                  {dataSubCategories?.map((elem) => (
                    <option key={elem._id} value={elem.message}>
                      {elem.message.substr(0, 50) + "...."}
                    </option>
                  ))}
                </Form.Select>
                <br />
                <Form.Control
                  className="textAreaCss"
                  as="textarea"
                  rows={8}
                  maxLength={500}
                  placeholder="Ingresa tu mensaje"
                  onChange={handleChangeMessage}
                  onBlur={handleBlur}
                  value={messageSelected}
                />

                <p className="contadorCss">
                  Máximo {totalCharacters - messageSelected.length} caracteres
                </p>
                {error && <p className="styles">{error}</p>}
              </Col>
            </Row>
            <br />
            <br />
          </Form.Group>
          <Form.Group>
            <div className="d-grid gap-1">
              <Button
                onClick={ValidationForm}
                style={{
                  backgroundColor: "#555555",
                  borderColor: "#555555",
                  fontWeight: "bold",
                }}
              >
                Siguiente
              </Button>
            </div>
          </Form.Group>
        </div>
      ) : (
        <RegisterComponent />
      )}

      <br />
    </motion.div>
  );
};

export default MessageComponent;
