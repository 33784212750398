import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "../App.css";
import ReturnComponent from "./controls/returnComponent";
import ImagePreview from "../components/controls/imagePreview";
import RegisterComponent from "./registerComponent";

function MessageUnlockedComponent() {

  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  const {state } = useLocation();
  const [width, setWidth] = useState(0);
  const carousel = useRef();
  const navigate = useNavigate();
  let arrImages = [];
  let video ='';
  
  useEffect(() => {
   
    if (state === null) {
      return navigate("/");
    } else {
      setWidth(carousel.current.scrollWidth - carousel.current.offsetWidth);
    }
  }, []);

  if (state === null) {
    return navigate("/");
  }else{
     arrImages = state.objData.images;
     video = state.objData.video;
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {state ? (
        <div>
          <ReturnComponent />
          <h1 className="registerTitleCss" style={{ textAlign: "center" }}>
            ¡Hola!
          </h1>
          <br />
          <p style={{ color: "gray", textAlign: "center" }}>
            <span style={{ fontWeight: "bold" }}>
              {state.objData.senderName}
            </span>{" "}
            te ha dedicado este mensaje con mucho afecto.
          </p>{" "}
          <hr />
          <br />
          <p style={{ color: "gray", textAlign: "center" }}>
            {" "}
            {state.objData.message}
          </p>
          <br />
          <Row className="justify-content-md-center">
            <Col style={{ display: "flex", justifyContent: "center" }}>
              <motion.div className="slider-container" ref={carousel}>
                <motion.div
                  className="slider"
                  drag="x"
                  dragConstraints={{ right: 0, left: -width }}
                >
                  {arrImages?.map((a, b) => (
                    <ImagePreview
                      image={arrImages[b]}
                      type="image"
                      key={b}
                    ></ImagePreview>
                  ))}
                  {video && (
                    <ImagePreview video={video} type="video"></ImagePreview>
                  )}
                </motion.div>
              </motion.div>
            </Col>
          </Row>
        </div>
      ) : (
        <RegisterComponent />
      )}
    </motion.div>
  );
}

export default MessageUnlockedComponent;
