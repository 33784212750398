import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";

function AlertSuccessComonent() {

   const navigate = useNavigate();

   const returnRegister = () => {
    navigate(`/register`);
  };

  return (
    <>
    <br/><br/>
      <Alert variant="success">
        <Alert.Heading>¡Felicidades!</Alert.Heading>
        <p>
         Tu información se guardó correctamente
        </p>
        <hr />
        <div className="d-flex justify-content-center">
          <Button  onClick={returnRegister} variant="outline-success">
            Ingresar otro folio
          </Button>
        </div>
      </Alert>
    </>
  )
}

export default AlertSuccessComonent
